import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import {
    GridActionsCellItem,
    useGridApiRef,
    GridAddIcon,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import DataGridComponent from "../../Widgets/DataGridComponent";
import { useSelector } from "react-redux";
import Axios from "axios";
import {
    excelSpreadsheetMIME,
    fetchData,
    getDownload,
} from "../../../js/utils/backend";
import {
    dateRangeValidation,
    requiredCell,
} from "../../../js/utils/inputValidations";
import { checkInteger } from "./DefinedFieldTypes/IntegerFieldDefinition";
import { checkFloat } from "./DefinedFieldTypes/FloatFieldDefinition";
import { checkText } from "./DefinedFieldTypes/TextFieldDefinition";
import { Box } from "@mui/system";
import { useHistory, useParams } from "react-router-dom";
import { dataGrid } from "../../../routers/MenuRouter";
import { ButtonToolbar } from "../../Widgets/Custom Inputs/Buttons";
import { t } from "react-i18nify";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { Button, Grid, IconButton } from "@mui/material";
import { MenuKebab, KebabItem } from "../../Widgets/MenuKebab";
import {
    dateOnlyToString,
    dateTimeToString,
    historyPush,
    isArrayLength,
} from "../../../js/utils/genericMethods";
import { ScreenLoading } from "../../Widgets/CustomLoadings";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import { SelectURLGrid } from "../../Widgets/Custom Inputs/Select";
import {
    BasicTooltip,
    DynamicIconImport,
} from "../../Widgets/StyledComponents";
import { BasicDialog } from "../../DataGrid/cst_DataGrid";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import AlertDetailGrid from "../../DataGrid/AlertDetailGrid";
import * as r from "../../../js/constants/routes";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import GenericDataGridMap from "../../ArcGIS/GenericDataGridMap";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import RestoreIcon from "@mui/icons-material/Restore";
import { ColumnHeaderRender } from "../../Layouts/CommonLayout";

/** Generic datagrid render; where columns and fields are determined by the received model from backend  */
const GenericDataGrid = ({
    paramURL = "",
    external,
    isReporting,
    isHistoryViewer,
}) => {
    const {
        historyItem,
        GenericGridTypeParent,
        GenericGridTypeChild,
        nodeID,
        element,
        elementID,
        searchInput,
        EDDFileID,
    } = useParams();
    const GenericGridType =
        GenericGridTypeChild !== "root"
            ? GenericGridTypeChild
            : GenericGridTypeParent;
    const apiRef = useGridApiRef();
    const token = useSelector(state => state.auth.Token);
    const locale = useSelector(state => state.i18n.locale);

    const [mapView, setMapView] = useState(false);
    const [alertFilter, setAlertFilter] = useState(false);
    const [gridConfig, setGridConfig] = useState(null);
    const [eddLineID, setEddLineID] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const history = useHistory();
    const [isSelecting, setIsSelecting] = useState(false);
    const [selection, setSelection] = useState([]);

    const [submitMode, setSubmitMode] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [forceGridRefresh, setForceGridRefresh] = useState(false);
    const [excelRequest, setExcelRequest] = useState("");

    const eddfileID = element === "EDDFileID" ? elementID : EDDFileID;

    /* We'll set the grid config, so the datagrid component is able to build the grid (= columns, headers, props) */
    const [currentGenericGridType, setCurrentGenericGridType] = useState(null);
    const [fetchGrid, setFetchGrid] = useState(false);

    /** useEffect meant to be sure that the new route which displays also a GenericDataGrid (e.g. from Station Air to Insitu observations)  DOC*/
    useEffect(() => {
        if (GenericGridType !== currentGenericGridType) {
            setGridConfig(null);
            setFetchGrid(true);
            setCurrentGenericGridType(GenericGridType);
        }
    }, [GenericGridType, currentGenericGridType]);

    /** STEP 1 : First fetch of the component, based on the type of needed generic grid, we'll fetch data about grid config and tell DataGridComponent how to build it; so later, DataGridComponent is able to bind data to each columns DOC*/
    useEffect(() => {
        let isSubscribed = true;
        let baseURL = GenericGridType;
        const reportingQuery = `/Query/GridInformation?queryID=${elementID}`;
        const externalQuery = `/grid/${baseURL}/external/${nodeID}`;

        let url = external
            ? externalQuery
            : isReporting
            ? reportingQuery
            : `/grid/${baseURL}/${nodeID}`;
        let config = {
            headers: { Authorization: "Bearer " + token },
        };
        const fetching = async () => {
            const fetchedGrid = await Axios.get(url, config);
            if (!isSubscribed) return;
            setGridConfig(fetchedGrid.data);
            setIsLoading(false);
        };

        /** Specific fetch in view of changes history (of an element, e.g. station or sampling). While recreating the grid (fetching the same corresponding grid where element belongs), some columns will be added and some data grids features will not be displayed. */
        const historyFetching = async () => {
            const historyURL = `/grid/${baseURL}/${nodeID}`;
            let historyFetchedGrid = await Axios.get(historyURL, config);

            /** Field to append in case of isHistoryViewer, to match data related to creation and update of element. CreatedByUser, LastUpdate, UpdatedByUser */
            const addentumFields = [
                {
                    Label: t("field.CreatedByUser"),
                    Fieldname: "CreatedByUser",
                    ReadOnly: true,
                    FieldType: "UserObject",
                    Description: t("field.CreatedByUserDescription"),
                    DisplayOrder: -1,
                    VisibleOnGrid: true,
                    DisplayWidth: 1.2,
                },
                {
                    Label: t("field.LastUpdate"),
                    Fieldname: "LastUpdate",
                    ReadOnly: true,
                    FieldType: "DateTime",
                    Description: t("field.LastUpdateDescription"),
                    DisplayOrder: -2,
                    VisibleOnGrid: true,
                    DisplayWidth: 1,
                },
                {
                    Label: t("field.UpdatedByUser"),
                    Fieldname: "UpdatedByUser",
                    ReadOnly: true,
                    FieldType: "UserObject",
                    Description: t("field.UpdatedByUserDescription"),
                    DisplayOrder: -3,
                    VisibleOnGrid: true,
                    DisplayWidth: 1.2,
                },
            ];

            let withAddentumFields = {
                ...historyFetchedGrid.data,
                Fields: historyFetchedGrid.data.Fields.concat(addentumFields),
            }; // TODO: overlook object because maybe the object should be cleaner whitout "Actions", "Discriminant"
            setGridConfig(withAddentumFields);
            setIsLoading(false);
        };
        if (fetchGrid && !isHistoryViewer) {
            fetching();
        } else if (fetchGrid && isHistoryViewer) {
            historyFetching();
        }
        return () => (isSubscribed = false);
    }, [
        paramURL,
        fetchGrid,
        token,
        GenericGridType,
        searchInput,
        nodeID,
        locale,
        elementID,
        external,
        isReporting,
        isHistoryViewer,
    ]);

    /** Selection handling */
    const handleSelectionClick = () => {
        setIsSelecting(true);
    };
    const handleCancelSelectionClick = () => {
        setIsSelecting(false);
        setSelection([]);
    };
    useEffect(() => {
        if (GenericGridType) {
            handleCancelSelectionClick();
            setMapView(false);
        }
    }, [GenericGridType, searchInput]);

    function cellValidation(params, fieldDescr, value) {
        // clean, maybe not used anymore
        switch (fieldDescr.FieldType) {
            case "Text":
                return checkText(fieldDescr, value);
            case "Integer":
                return checkInteger(fieldDescr, Number(value));
            case "Float":
                return checkFloat(fieldDescr, Number(value));
            case "Date":
            case "DateTime":
                return dateRangeValidation(
                    params,
                    apiRef,
                    "Start/End Date",
                    "StartDate",
                    "EndDate"
                    //fieldDescr.Mandatory
                );
            default:
                return true;
        }
    }

    function determineType(fieldType) {
        switch (
            fieldType // by default it's "string" (= "Text" type) / still not handled (and maybe will never be) : "boolean", "singleSelect" /
        ) {
            case "Integer":
                return "number";
            case "Float":
                return "number";
            case "Date":
                return "date";
            case "DateTime":
                return "dateTime";
            default:
                break;
        }
    }

    function hasErrors(value, isMandatory, isValid) {
        if (isMandatory) {
            return !isValid;
        } else {
            if (value) {
                return !isValid;
            } else if (!value) {
                return false;
            }
        }
    }

    const gridColumnsDefinition = gridConfig?.Fields?.sort((a, b) => {
        if (isReporting) {
            return a.EntityName - b.EntityName;
        } else {
            return new Date(a?.DisplayOrder) - new Date(b?.DisplayOrder);
        }
    })?.map((item, index) => {
        if (
            item.Fieldname === "Point" ||
            (isReporting && item.Fieldname.includes("_Point"))
        ) {
            return {
                field: `${item.Fieldname}`,
                renderHeader: () => (
                    <ColumnHeaderRender item={item} isReporting={isReporting} />
                ),
                sortable: !isReporting,
                valueGetter: params =>
                    isReporting
                        ? params?.row?.[item.Fieldname]?.coordinates
                        : params?.row?.PointString,
                width: 300,
            };
        } else if (
            item.Fieldname === "Polygon" ||
            (isReporting && item.Fieldname.includes("_Polygon"))
        ) {
            return {
                field: `${item.Fieldname}`,
                renderHeader: () => (
                    <ColumnHeaderRender item={item} isReporting={isReporting} />
                ),
                sortable: !isReporting,
                valueGetter: params =>
                    isReporting
                        ? params?.row?.[item.Fieldname]?.coordinates
                        : params?.row?.PolygonString,
                width: 300,
            };
        } else if (item.FieldType === "Alert") {
            return {
                field: item.Fieldname,
                renderCell: params => (
                    <BasicTooltip
                        title={
                            params?.value
                                ? t("common.SeeAlerts")
                                : t("common.NoAlerts")
                        }
                    >
                        <div>
                            <IconButton
                                disableRipple={!params?.value}
                                disabled={!params?.value}
                                onClick={() => {
                                    setEddLineID(params.row.ID);
                                    setOpenAlertDialog(true);
                                }}
                            >
                                {params?.value ? (
                                    <WarningOutlinedIcon
                                        sx={{ width: 18, height: 18 }}
                                    />
                                ) : (
                                    <DoneOutlinedIcon
                                        sx={{ width: 18, height: 18 }}
                                    />
                                )}
                            </IconButton>
                        </div>
                    </BasicTooltip>
                ),
                renderHeader: () => (
                    <ColumnHeaderRender item={item} isReporting={isReporting} />
                ),
                headerAlign: "left",
                editable: false,
                width: 100,
                align: "center",
                /* Memo: Sort is disabled on reportig generic grids (since backend hasn't enabled it) */
                sortable: !isReporting,
            };
        } else if (item?.Lookup) {
            return {
                field: `${item.LookupFieldname}`,
                renderHeader: () => (
                    <ColumnHeaderRender item={item} isReporting={isReporting} />
                ),
                headerAlign: "left",
                editable: item.ReadOnly ? false : true,
                width: item.DisplayWidth * 200,
                align: "left",
                type: determineType(item.FieldType),
                hide: !item.VisibleOnGrid,
                valueGetter: params => {
                    if(params!==undefined){
                        return isReporting
                            ? params?.row?.[item.LookupFieldname]
                            : params.row?.[`${item.LookupFieldname}_select`]?.[
                                item.LookupValueField
                            ];
                    }
                },
                renderEditCell: params => (
                    <SelectURLGrid
                        fieldName={item.Fieldname} // e.g SubTypeID
                        URL={`/${item.Lookup}`} // e.g SubType
                        {...params}
                    />
                ),
                preProcessEditCellProps: params => {
                    const isMandatory = item.Mandatory
                        ? requiredCell(
                              params,
                              apiRef,
                              item.Label,
                              item.Fieldname
                          )
                        : null;
                    const hasError = isMandatory;
                    return { ...params.props, error: hasError };
                },
                sortable: !isReporting,
            };
        } else if (item.FieldType.includes("Date")) {
            return {
                field: item.Fieldname,
                renderHeader: () => (
                    <ColumnHeaderRender item={item} isReporting={isReporting} />
                ),
                valueFormatter: params =>
                    item?.FieldType === "Date"
                        ? dateOnlyToString(params?.value)
                        : dateTimeToString(params?.value, item?.FieldType),
                headerAlign: "left",
                editable: item.ReadOnly ? false : true,
                width: item.DisplayWidth * 200,
                align: "left",
                type: determineType(item.FieldType),
                hide: !item.VisibleOnGrid,
                preProcessEditCellProps: params => {
                    const isMandatory = item.Mandatory
                        ? requiredCell(
                              params,
                              apiRef,
                              item.Label,
                              item.Fieldname
                          )
                        : null;
                    const isValid = !dateRangeValidation(
                        params,
                        apiRef,
                        "Start/End Date",
                        "StartDate",
                        "EndDate"
                    );
                    return {
                        ...params.props,
                        error: hasErrors(
                            params.props.value,
                            isMandatory,
                            isValid
                        ),
                    };
                },
                sortable: !isReporting,
            };
        } else if (item.FieldType === "UserObject") {
            return {
                field: item.Fieldname,
                renderHeader: () => (
                    <ColumnHeaderRender item={item} isReporting={isReporting} />
                ),
                headerAlign: "left",
                editable: false,
                width: item.DisplayWidth * 200,
                valueFormatter: params => {
                    return `${params?.value?.Name} ${
                        params?.value?.IGG ? `| ${params?.value?.IGG}` : ""
                    } ${params?.value?.External ? "| External" : ""}`;
                },
                type: determineType(item.FieldType),
                hide: !item.VisibleOnGrid,
                preProcessEditCellProps: params => {
                    const isMandatory = item.Mandatory
                        ? requiredCell(
                              params,
                              apiRef,
                              item.Label,
                              item.Fieldname
                          )
                        : null;
                    const isValid = cellValidation(
                        params,
                        item,
                        params.props.value
                    );
                    return {
                        ...params.props,
                        error: hasErrors(
                            params.props.value,
                            isMandatory,
                            isValid
                        ),
                    };
                },
                sortable: !isReporting,
            };
        } else {
            return {
                field: item.Fieldname,
                renderHeader: () => (
                    <ColumnHeaderRender item={item} isReporting={isReporting} />
                ),
                headerAlign: "left",
                editable: item.ReadOnly ? false : true,
                width: item.DisplayWidth * 200,
                align: "left",
                valueFormatter: params => params,
                type: determineType(item.FieldType),
                hide: !item.VisibleOnGrid,
                preProcessEditCellProps: params => {
                    const isMandatory = item.Mandatory
                        ? requiredCell(
                              params,
                              apiRef,
                              item.Label,
                              item.Fieldname
                          )
                        : null;
                    const isValid = cellValidation(
                        params,
                        item,
                        params.props.value
                    );
                    return {
                        ...params.props,
                        error: hasErrors(
                            params.props.value,
                            isMandatory,
                            isValid
                        ),
                    };
                },
                sortable: !isReporting,
            };
        }
    });

    /** Creating additional (hidden) columns that will get the ID of the associated selected option from lookup, these will be added to the grid columns along the other previously created */
    const additionalIDs = () => {
        let gettingLookupFields = gridConfig?.Fields?.filter(
            element => element.Lookup
        );
        const parentField = {
            field: element,
            hide: true,
            valueGetter: () => elementID,
            valueSetter: params => ({
                ...params.row,
                [element]: elementID,
            }),
        };
        let lookupsFieldIDs = gettingLookupFields?.map((item, index) => {
            return {
                field: item.Fieldname,
                hide: true,
                valueGetter: params =>
                    params.row?.[`${item.Lookup}_select`]?.ObjectID,
            };
        });
        lookupsFieldIDs?.push(parentField);
        return lookupsFieldIDs;
    };

    
    const gridSpecificColumns = gridColumnsDefinition?.concat(additionalIDs());
    /** We use an id fieldname for each Lookup, but these should not be displayed to the grid. So the solution is to create and spread object (fieldname as key, assigned false) to the columnVisibilityModel' */
    const idColumnsToHide = gridSpecificColumns?.reduce(
        (o, key) => (key?.hide ? Object.assign(o, { [key.field]: false }) : o),
        {}
    );

    const [visitedRow, setVisitedRow] = useState();

    const specificActionsColumns = (params, _editing, isLocalLoading) =>
        gridConfig.Actions?.sort(
            (a, b) => new Date(a?.Order) - new Date(b?.Order)
        )?.map((item, _index) => {
            // Some samplings have instructions to which entity the action will redirect; e.g. BiologicalSamplings : InSitu will be on Field or Underground measurement depending of which type of station is the sampling from.
            if (
                item.EnabledForStations === null ||
                item.EnabledForStations.includes(params.row.StationType)
            ) {
                return (
                    <Button
                        key={item?.ToolTip}
                        size="small"
                        sx={{
                            textTransform: "capitalize",
                            width: "100%",
                            display: "block",
                        }}
                        disabled={isLocalLoading}
                    >
                        <KebabItem
                            disabled={isLocalLoading}
                            label={item.ToolTip}
                            icon={
                                item && (
                                    <DynamicIconImport
                                        iconName={item?.MUIIconName}
                                        iconTooltip={item?.ToolTip}
                                    />
                                )
                            }
                            key={`${item.LinkedGrid}`}
                            onClick={async () => {
                                setVisitedRow(params.row.ID);
                                setIsLoading(true);
                                if (item.isChildGenericGridType === false) {
                                    const nodeIDLinkedGridFetched =
                                        await fetchData(
                                            `${r.oTEntityTree}/nodeIDPerStationType/${elementID}/${GenericGridType}`,
                                            token,
                                            "List"
                                        );

                                    const nodeIDLinkedGrid =
                                        nodeIDLinkedGridFetched.data;
                                    return historyPush(
                                        history,
                                        `${dataGrid}/${item.LinkedGrid}/${nodeIDLinkedGrid}/root/OTEntityID/${elementID}/${params.row.Name}`,
                                        { withBackButton: true }
                                    );
                                } else {
                                    switch (item.LinkedGrid) {
                                        // Documents link redirection occurs only for stations
                                        case "Documents":
                                            return historyPush(
                                                history,
                                                `/${item.LinkedGrid}/${GenericGridType}/${params.row.ID}/true/${elementID}/${nodeID}`
                                            );
                                        case "PhotoVideo":
                                            return historyPush(
                                                history,
                                                `/${item.LinkedGrid}/${GenericGridType}/${params.row.ID}/true/${elementID}/${nodeID}`
                                            );
                                        default:
                                            return historyPush(
                                                history,
                                                `${dataGrid}/${GenericGridType}/${nodeID}/${
                                                    item.LinkedGrid
                                                }/${
                                                    item.SpecificParentFieldForChild ??
                                                    gridConfig?.ParentFieldForChild
                                                }/${params.row.ID}`+
                                                (eddfileID
                                                   ? `/EDDFileID/${eddfileID}`
                                                   : "")
                                       
                                            );
                                    }
                                }
                            }}
                        />
                    </Button>
                );
            } else {
                return null;
            }
        });

    const AlertGridDialog = useCallback(() => {
        return (
            <AlertDetailGrid
                toolbar={{
                    disableToolbar: true,
                }}
                otEntityID={elementID}
                eddLineID={eddLineID}
                isAdvancedSelectorGrid
                disableTitle
                isStandalone={false}
            />
        );
    }, [eddLineID, elementID]);

    const menuKebab = (params, editing, isLocalLoading) => {
        let finalMenu = [
            <MenuKebab disabled={editing || isLocalLoading}>
                {specificActionsColumns(params, editing, isLocalLoading)}
            </MenuKebab>,
        ];
        if (gridConfig?.UserCanEditGrid && !isHistoryViewer) {
            finalMenu = [
                ...finalMenu,
                <GridActionsCellItem
                    label="History"
                    icon={
                        <BasicTooltip title={t("common.ChangesHistory")}>
                            <RestoreIcon />
                        </BasicTooltip>
                    }
                    onClick={() => {
                        setVisitedRow(params.id);
                        return historyPush(
                            history,
                            `/history/${params.id}${history.location.pathname}`
                        );
                    }}
                    disabled={editing || isLocalLoading}
                />,
                <GridActionsCellItem
                    label="Edit"
                    icon={
                        <BasicTooltip title={t("common.Edit")}>
                            <EditOutlinedIcon />
                        </BasicTooltip>
                    }
                    onClick={() => {
                        setVisitedRow(params.id);
                        setOpenEditDialog(true);
                        setSubmitMode("edit");
                    }}
                    disabled={editing || isLocalLoading}
                />,
            ];
        }
        return finalMenu;
    };

    const [openEditDialog, setOpenEditDialog] = useState(false);

    /** State and useEffect() meant to fetch and display label of the grid. DOC*/
    const [elementData, setElementData] = useState();
    useEffect(
        () => {
            let isSubscribed = true;
            /** Getting parent information (Name,value, etc) to display on children's title */
            let itemID = historyItem || elementID;

            const fetching = async () => {
                const response = await fetchData(
                    GenericGridTypeParent,
                    token,
                    "ID",
                    itemID
                );
                setElementData(response.data);
            };
            if (gridConfig && itemID && isSubscribed) {
                fetching();
            } else {
                setElementData("");
            }
            return () => (isSubscribed = false);
        },
        // eslint-disable-next-line
        [elementID, historyItem, token]
    );

    const leftPinnedField = gridConfig?.Fields?.filter(field =>
        Boolean(field.Pinned)
    )?.map(x => x.Fieldname);

    const paramURLProvided = isReporting
        ? `queryID=${elementID}`
        : `${element}=${elementID}` +
        (Boolean(EDDFileID) ? `&EDDFileID=${EDDFileID}` : "");
    /** Component will be loaded onClick; form values are correctly discarded */
    const GenericEditionPanel = lazy(() =>
        import("../../Views/Edition panels/GenericEditionPanel")
    );

    const elementLabel = elementData
        ? `: ${elementData[gridConfig?.ValueParentField] ?? elementData.Name}`
        : "";
    return (
        <>
            {gridSpecificColumns &&
            specificActionsColumns &&
            gridConfig &&
            !isLoading ? (
                mapView ? (
                    <GenericDataGridMap
                        title={gridConfig?.Label}
                        selection={selection}
                        toolbarComponent={
                            <ButtonToolbar
                                titleTooltip={t("common.AccessStationsGrid")}
                                title={t("common.GridView")}
                                startIcon={
                                    <TableRowsOutlinedIcon
                                        sx={{ marginRight: "-4px" }}
                                    />
                                }
                                onClick={() => setMapView(false)}
                            />
                        }
                    />
                ) : (
                    <DataGridComponent
                        apiRef={apiRef}
                        baseURL={
                            isHistoryViewer
                                ? `${GenericGridType}/history/${historyItem}`
                                : GenericGridType ?? "Query/Grid"
                        }
                        /* TODO : Extract the label code out of there */
                        label={
                            isHistoryViewer ? (
                                <Grid container sx={{ display: "flex" }}>
                                    <Grid item>
                                        {`${t("common.ChangesHistory")} : ${
                                            gridConfig?.Label
                                        } `}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                marginTop: 2,
                                                typography: "body1",
                                                fontWeight: 500,
                                            }}
                                        >
                                            {elementData.Name}
                                        </Box>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container sx={{ display: "flex" }}>
                                    <Grid item>
                                        {gridConfig?.Label}
                                        {elementLabel}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box
                                            sx={{
                                                marginTop: 2,
                                                typography: "body1",
                                                fontWeight: 500,
                                            }}
                                        >
                                            {gridConfig.Discriminant}
                                        </Box>
                                    </Grid>
                                </Grid>
                            )
                        }
                        gridSpecificColumns={gridSpecificColumns}
                        specificActionsColumns={menuKebab}
                        initialState={
                            isHistoryViewer
                                ? {
                                      /* Pinned columns, basically every Field comes with a boolean property "Pinned", which the following configuration prop will reflect.  */
                                      sorting: {
                                          sortModel: [
                                              {
                                                  field: gridConfig.DefaultSort
                                                      ? gridConfig.DefaultSort
                                                      : "LastUpdate",
                                                  sort: gridConfig.DefaultSortDirection
                                                      ? gridConfig.DefaultSortDirection
                                                      : "desc",
                                              },
                                          ],
                                      },
                                      pinnedColumns: {
                                          right: ["actions"],
                                      },
                                  }
                                : {
                                      sorting: {
                                          sortModel: [
                                              {
                                                  field: gridConfig.DefaultSort
                                                      ? gridConfig.DefaultSort
                                                      : "LastUpdate",
                                                  sort: gridConfig.DefaultSortDirection
                                                      ? gridConfig.DefaultSortDirection
                                                      : "desc",
                                              },
                                          ],
                                      },
                                      pinnedColumns: {
                                          left: [
                                              GRID_CHECKBOX_SELECTION_COL_DEF.field,
                                              ...leftPinnedField,
                                          ],
                                          right: ["actions"],
                                      },
                                  }
                        }
                        isEditDisabled={true}
                        paramURL={
                            isHistoryViewer
                                ? ``
                                : paramURLProvided +
                                  (alertFilter
                                      ? `&${r.filter.genericFilter(
                                            "Alert",
                                            alertFilter
                                        )}`
                                      : "")
                        }
                        reportingRecords={isReporting}
                        visitedRow={visitedRow}
                        gridConfig={gridConfig}
                        setGridConfig={e => setGridConfig(e)}
                        GenericGridType={GenericGridType ?? "Query/Grid"}
                        currentGenericGridType={
                            currentGenericGridType ?? "Query/Grid"
                        }
                        withBackButton={isHistoryViewer} // is history viewer or reporting
                        editingRights={gridConfig.UserCanEditGrid}
                        forceGridRefresh={forceGridRefresh}
                        setForceGridRefresh={e => setForceGridRefresh(e)}
                        setExcelRequest={e => setExcelRequest(e)}
                        hideActionColumn={isReporting || isHistoryViewer}
                        toolbar={
                            isHistoryViewer
                                ? { add: false }
                                : {
                                      add: null,
                                      newAction: !isReporting && (
                                          <>
                                              {gridConfig?.UserCanEditGrid && (
                                                  <ButtonToolbar
                                                      titleTooltip={t(
                                                          "common.Add"
                                                      )}
                                                      title={t("common.Add")}
                                                      startIcon={
                                                          <GridAddIcon />
                                                      }
                                                      disabled={isSelecting}
                                                      onClick={() => {
                                                          setOpenEditDialog(
                                                              true
                                                          );
                                                          setSubmitMode("add");
                                                      }}
                                                  />
                                              )}
                                              {!isReporting && (
                                                  <ButtonToolbar
                                                      titleTooltip={t(
                                                          "common.ExcelExport"
                                                      )}
                                                      title={t(
                                                          "common.ExcelExport"
                                                      )}
                                                      startIcon={
                                                          <DownloadOutlinedIcon
                                                              sx={{
                                                                  marginRight:
                                                                      "-4px",
                                                              }}
                                                          />
                                                      }
                                                      disabled={isSelecting}
                                                      onClick={() => {
                                                          /** The request should absolutely be the same as paginated list for grid, while ommiting specific grid-related things like pageSize and pageNumber */
                                                          let url = `${GenericGridType}/ExportToExcel?${paramURLProvided}&${excelRequest}`;
                                                          getDownload(
                                                              url,
                                                              token,
                                                              excelSpreadsheetMIME,
                                                              `${GenericGridType}Excel`
                                                          );
                                                      }}
                                                  />
                                              )}
                                              {gridConfig.Selectable && (
                                                  <>
                                                      {!isSelecting ? (
                                                          <ButtonToolbar
                                                              titleTooltip={t(
                                                                  "common.Select"
                                                              )}
                                                              title={t(
                                                                  "common.Select"
                                                              )}
                                                              startIcon={
                                                                  <TaskAltOutlinedIcon
                                                                      sx={{
                                                                          marginRight:
                                                                              "-4px",
                                                                      }}
                                                                  />
                                                              }
                                                              onClick={
                                                                  handleSelectionClick
                                                              }
                                                          />
                                                      ) : (
                                                          <>
                                                              <ButtonToolbar
                                                                  titleTooltip={t(
                                                                      "common.AccessStationsMap"
                                                                  )}
                                                                  title={t(
                                                                      "common.MapView"
                                                                  )}
                                                                  startIcon={
                                                                      <MyLocationOutlinedIcon
                                                                          sx={{
                                                                              marginRight:
                                                                                  "-4px",
                                                                          }}
                                                                      />
                                                                  }
                                                                  onClick={() =>
                                                                      setMapView(
                                                                          true
                                                                      )
                                                                  }
                                                                  disabled={
                                                                      !isArrayLength(
                                                                          selection
                                                                      )
                                                                  }
                                                              />
                                                              {gridConfig.GintExport && (
                                                                  <ButtonToolbar
                                                                      titleTooltip={t(
                                                                          "common.GINTExport"
                                                                      )}
                                                                      title={t(
                                                                          "common.GINTExport"
                                                                      )}
                                                                      startIcon={
                                                                          <DownloadOutlinedIcon
                                                                              sx={{
                                                                                  marginRight:
                                                                                      "-4px",
                                                                              }}
                                                                          />
                                                                      }
                                                                      onClick={() => {
                                                                          /** The request should absolutely be the same as paginated list for grid, while ommiting specific grid-related things like pageSize and pageNumber */
                                                                          let url = `${GenericGridType}/ExportToGint?${paramURLProvided}&${excelRequest}`;
                                                                          getDownload(
                                                                              url,
                                                                              token,
                                                                              excelSpreadsheetMIME,
                                                                              `${GenericGridType}Excel`,
                                                                              isArrayLength(
                                                                                  selection
                                                                              ) &&
                                                                                  selection
                                                                          );
                                                                      }}
                                                                      disabled={
                                                                          !isArrayLength(
                                                                              selection
                                                                          )
                                                                      }
                                                                  />
                                                              )}
                                                              <ButtonToolbar
                                                                  titleTooltip={t(
                                                                      "view.document.CancelMove"
                                                                  )}
                                                                  title={t(
                                                                      "common.Cancel"
                                                                  )}
                                                                  startIcon={
                                                                      <CancelOutlinedIcon
                                                                          sx={{
                                                                              marginRight:
                                                                                  "-4px",
                                                                          }}
                                                                      />
                                                                  }
                                                                  onClick={
                                                                      handleCancelSelectionClick
                                                                  }
                                                              />
                                                          </>
                                                      )}
                                                  </>
                                              )}
                                          </>
                                      ),
                                      newFilter: (
                                          <>
                                              {!isReporting && (
                                                  <ButtonToolbar
                                                      titleTooltip={t(
                                                          "common.AlertsOnly"
                                                      )}
                                                      title={t(
                                                          "common.AlertsOnly"
                                                      )}
                                                      disabled={isSelecting}
                                                      startIcon={
                                                          alertFilter ? (
                                                              <CheckBoxIcon
                                                                  sx={{
                                                                      mb: "1px",
                                                                  }}
                                                              />
                                                          ) : (
                                                              <CheckBoxOutlineBlankOutlinedIcon
                                                                  sx={{
                                                                      marginRight:
                                                                          "-4px",
                                                                      mb: "1px",
                                                                  }}
                                                              />
                                                          )
                                                      }
                                                      onClick={() => {
                                                          setAlertFilter(
                                                              !alertFilter
                                                          );
                                                      }}
                                                  />
                                              )}
                                          </>
                                      ),
                                  }
                        }
                        onSelectionModelChange={newSelectionModel => {
                            setSelection(newSelectionModel);
                        }}
                        selectionModel={selection}
                        isDeleteDisabled={isSelecting || isHistoryViewer}
                        checkboxSelection={isSelecting}
                        columnVisibilityModel={{
                            ...idColumnsToHide,
                            Point: false,
                            Polygon: false,
                            OTEntityID: false,
                            actions: !isSelecting,
                        }}
                    />
                )
            ) : (
                <ScreenLoading />
            )}
            {openAlertDialog ? (
                <Suspense fallback={null}>
                    <BasicDialog
                        label={t("dialog.Alerts")}
                        open={openAlertDialog}
                        closeDialog={() => setOpenAlertDialog(false)}
                        Content={AlertGridDialog}
                    />
                </Suspense>
            ) : null}
            {openEditDialog ? (
                <Suspense fallback={null}>
                    <GenericEditionPanel
                        openEditDialog={openEditDialog}
                        setOpenEditDialog={setOpenEditDialog}
                        baseURL={GenericGridType}
                        rowID={visitedRow}
                        gridConfig={gridConfig}
                        parentField={{
                            Key: element,
                            Value: elementID,
                        }}
                        submitMode={submitMode}
                        setForceGridRefresh={e => setForceGridRefresh(e)}
                        setVisitedRow={e => setVisitedRow(e)}
                    />
                </Suspense>
            ) : null}
        </>
    );
};

export default GenericDataGrid;
