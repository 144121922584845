import React, { useCallback, useEffect, useState } from "react";
import {
    GridActionsCellItem,
    useGridApiRef,
    GridAddIcon,
} from "@mui/x-data-grid-pro";
import * as r from "../../js/constants/routes";
import { requiredCell } from "../../js/utils/inputValidations";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useDispatch, useSelector } from "react-redux";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";

import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { updateHighlightedRows } from "../../js/redux/actions/userPref";
import { useLocation } from "react-router-dom";
import { disableKeyCellEdit, TranslationDialog } from "./cst_DataGrid";
import { Paper } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { EnglishValueDialog, NewRefDialog } from "./ReferencesDataGrid";
import { SelectURLGrid } from "../Widgets/Custom Inputs/Select";
import { getDownload, excelSpreadsheetMIME, jsonMIME } from "../../js/utils/backend";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

const AnalyteGrid = ({ baseURL, label, paramURL = "&Primary=true" }) => {
    const apiRef = useGridApiRef();
    const cellFocusField = "Value";
    const dispatch = useDispatch();
    const location = useLocation();
    const token = useSelector(state => state.auth.Token);

    const { CompanyManager, HoldingManager } = useSelector(state => state.auth);
    const editingRights = CompanyManager;
    const [addRequest, setAddRequest] = useState(false);

    const locale = useSelector(state => state.i18n.locale);
    const [propsEnglishValue, setOpenEnglishValue] = useState(false);
    const beforeCommitRow = useCallback(
        row => {
            if (row.isNew && locale !== "ENG") {
                return new Promise((resolve, reject) => {
                    setOpenEnglishValue({
                        open: true,
                        setOpen: setOpenEnglishValue,
                        resolve,
                        reject,
                        row,
                        label,
                    });
                }).then(englishValue => ({
                    ...row,
                    EnglishValue: englishValue,
                }));
            }
            return row;
        },
        [setOpenEnglishValue, locale, label]
    );

    const DetailPanelContent = ({ row }) => {
        const apiRefDetailPanel = useGridApiRef();

        const detailGridSpecificColumns = [
            {
                field: "Value",
                headerName: t("field.Value"),
                flex: 1,
                editable: true,
                sortable: true,
            },
            {
                field: "Key",
                width: 150,
                editable: true,
                sortable: false,
                valueGetter: _params => row.Key,
                valueSetter: params => ({
                    ...params.row,
                    Key: row.Key,
                }),
            },
            {
                field: "Cas",
                width: 150,
                editable: true,
                sortable: false,
                valueGetter: _params => row.Cas,
                valueSetter: params => ({
                    ...params.row,
                    Cas: row.Cas,
                }),
            },
            {
                field: "SynonymID",
                width: 150,
                editable: true,
                sortable: false,
                valueGetter: _params => row.ID,
                valueSetter: params => ({
                    ...params.row,
                    SynonymID: row.ID,
                }),
            },
            {
                field: "Ref_AnalysisGroupID",
                width: 150,
                editable: true,
                sortable: false,
                valueGetter: _params =>
                    row.Ref_AnalysisGroup_select
                        ? row.Ref_AnalysisGroup_select.ObjectID
                        : null,
                valueSetter: params => ({
                    ...params.row,
                    Ref_AnalysisGroupID: row.Ref_AnalysisGroup_select
                        ? row.Ref_AnalysisGroup_select.ObjectID
                        : null,
                }),
            },
        ];

        const detailSpecificActionsColumns = (
            params,
            editing,
            isLocalLoading
        ) => [
            <>
                <GridActionsCellItem
                    //
                    label={t("common.Translate")}
                    icon={
                        <BasicTooltip title={t("common.Translate")}>
                            <LanguageIcon />
                        </BasicTooltip>
                    }
                    onClick={() => {
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: currentLocation,
                                VisitedRowID: params.row.ID,
                            })
                        );
                        setOpen(true);
                        setRowParams(params.row);
                    }}
                    disabled={editing || isLocalLoading}
                    //
                />
            </>,
        ];

        /* To highlight the parent row when the users displays master detail panel */
        useEffect(() => {
            if (row) {
                dispatch(
                    updateHighlightedRows({
                        CurrentLocation: currentLocation,
                        VisitedRowID: row.ID,
                    })
                );
            }
        }, [row]);

        return (
            <Paper sx={{ m: 1, p: 1 }}>
                <DataGridComponent
                    apiRef={apiRefDetailPanel}
                    label={"Synonyms"}
                    columnVisibilityModel={{
                        Key: false,
                        Cas: false,
                        SynonymID: false,
                        Ref_AnalysisGroupID: false,
                    }}
                    baseURL={baseURL}
                    paramURL={r.filter.genericFilter("SynonymID", row.ID)}
                    cellFocusField={cellFocusField}
                    disableTitle
                    disableResearch
                    boxStyle={{ padding: 0, margin: 0 }}
                    gridSpecificColumns={detailGridSpecificColumns}
                    specificActionsColumns={detailSpecificActionsColumns}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "Value", sort: "asc" }],
                        },
                    }}
                    isStandalone={false}
                />
            </Paper>
        );
    };

    const currentLocation = location.pathname;

    const [open, setOpen] = useState(false);
    const [rowParams, setRowParams] = useState();

    const getDetailPanelContent = useCallback(params => {
        return <DetailPanelContent row={params.row} />;
    }, []);

    const gridSpecificColumns = [
        {
            field: "Key",
            headerName: t("field.Key"),
            flex: 1,
            editable: true,
            sortable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "Key", "Key");
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Value",
            headerName: t("field.Value"),
            flex: 1,
            editable: true,
            sortable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "Value", "Value");
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Cas",
            headerName: t("field.Cas"),
            width: 150,
            editable: true,
            sortable: true,
        },
        {
            field: "Ref_AnalysisGroup_select",
            sortable: false,
            headerName: t("field.AnalysisGroup"),
            flex: 1,
            editable: true,
            valueFormatter: params => params.Value,
            renderEditCell: params => (
                <SelectURLGrid
                    fieldName={"Ref_AnalysisGroupID"}
                    URL={r.analysisGroup.route}
                    {...params}
                />
            ),
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "AnalysisGroup",
                    "Ref_AnalysisGroup_select"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Ref_AnalysisGroupID",
            editable: false,
        },
        {
            field: "Primary",
            width: 150,
            editable: true,
            sortable: false,
            valueGetter: _params => true,
            valueSetter: params => ({
                ...params.row,
                Primary: true,
            }),
        },
    ];

    const specificActionsColumns = (params, editing, isLocalLoading) => [
        <>
            {editingRights && (
                <GridActionsCellItem
                    //
                    label={t("common.Translate")}
                    icon={
                        <BasicTooltip title={t("common.Translate")}>
                            <LanguageIcon />
                        </BasicTooltip>
                    }
                    onClick={() => {
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: currentLocation,
                                VisitedRowID: params.row.ID,
                            })
                        );
                        setOpen(true);
                        setRowParams(params.row);
                    }}
                    disabled={editing || isLocalLoading}
                    //
                />
            )}
        </>,
    ];

    return (
        <>
            {open && (
                <TranslationDialog
                    label={label}
                    open={open}
                    setOpen={setOpen}
                    rowParams={rowParams}
                    baseURL={baseURL}
                />
            )}

            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                cellFocusField={cellFocusField}
                columnVisibilityModel={{
                    Primary: false,
                    Ref_AnalysisGroupID: false,
                }}
                label={label}
                getDetailPanelHeight={() => "auto"}
                gridSpecificColumns={gridSpecificColumns}
                specificActionsColumns={specificActionsColumns}
                paramURL={paramURL}
                isCellEditable={(params) => disableKeyCellEdit(params)}
                toolbar={{
                    newAction: (
                        <>
                            {!CompanyManager && !HoldingManager && (
                                <ButtonToolbar
                                    titleTooltip={t("common.addNewRef")}
                                    title={t("common.addNewRef")}
                                    startIcon={<GridAddIcon />}
                                    onClick={() => {
                                        setAddRequest({ node: {} });
                                    }}
                                />
                            )}
                            <ButtonToolbar
                                titleTooltip={t(
                                    "common.ExcelExport"
                                )}
                                title={t(
                                    "common.ExcelExport"
                                )}
                                startIcon={
                                    <DownloadOutlinedIcon
                                        sx={{ marginRight: "-4px" }}
                                    />
                                }
                                onClick={() => {
                                    /** The request should absolutely be the same as paginated list for grid, while ommiting specific grid-related things like pageSize and pageNumber */
                                    let url = `${baseURL}/ExportToExcel`;
                                    getDownload(
                                        url,
                                        token,
                                        excelSpreadsheetMIME,
                                        `${baseURL}`
                                    );
                                }}
                            />
                            <ButtonToolbar
                                titleTooltip={t(
                                    "common.JsonExport"
                                )}
                                title={t(
                                    "common.JsonExport"
                                )}
                                startIcon={
                                    <DownloadOutlinedIcon
                                        sx={{ marginRight: "-4px" }}
                                    />
                                }
                                onClick={() => {
                                    let url = `${baseURL}/ExportToJson`;
                                    getDownload(
                                        url,
                                        token,
                                        jsonMIME,
                                        `${baseURL}`
                                    );
                                }}
                            />
                        </>
                    ),
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "Value", sort: "asc" }],
                    },
                }}
                getDetailPanelContent={apiRef && getDetailPanelContent}
                components={{
                    DetailPanelExpandIcon: ChevronRightIcon,
                    DetailPanelCollapseIcon: ExpandMoreIcon,
                }}
                editingRights={editingRights}
                beforeCommitRow={beforeCommitRow}
            />
            <NewRefDialog
                reference={"Analytes"}
                editNewRefOpen={addRequest}
                seteditNewRefOpen={setAddRequest}
            />
            <EnglishValueDialog {...propsEnglishValue} />
        </>
    );
};

export default AnalyteGrid;
