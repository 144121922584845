import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./js/redux/store/index";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./index.css";
import App from "./App";
import { LicenseInfo } from '@mui/x-data-grid-pro';



LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATAGRID_LICENSE_KEY);

const root = createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </PersistGate>

        <ReduxToastr
            timeOut={5000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar={false}
            closeOnToastrClick={false}
        />
    </Provider>
);
