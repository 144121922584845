import SharedSettings from "../common/sharedSettings.json";

const FRA = {
    common: {
        AccessWorkflow: "Accéder au flux de travail",
        AccessStationsGrid: "Accès à la grille des stations",
        AccessStationsMap: "Accès à la carte des stations",
        AccessZonesGrid: "Accès à la grille des zones",
        AccessZonesMap: "Accès à la carte des zones",
        Actions: "Actions",
        Add: "Ajouter",
        AddAnElement: "Ajouter un élément",
        AddSubEntity: "Ajouter une Sous-Entité",
        addNewRef: "Demander l'ajout d'une nouvelle référence",
        Assign: "Assigner un statut",
        AssignToInternalUsers: "Assigner le statut d'utili. interne",
        AssignToExternalUsers: "Assigner le statut d'utili. externe",
        AlertsOnly: "Alertes uniquement",
        AlertsToSee:
            "Il y a des alertes sur cet élément. Plus d'informations sur la grille.",
        ApplicationDown: "L'application est actuellement en maintenance",
        ApplicationUnderMaintenance: "L'appli. sera en maintenance du %{startDate} jusqu'au %{endDate}",
        Back: "Retour",
        Bookmarks: "Signets",
        Cancel: "Annuler",
        CannotAccessThisPage: "Cette page est inaccessible",
        Continue: "Continuer",
        ChangesHistory: "Historique des modifications",
        CoordinateSystem: "Système de coordonnées",
        CreatingNew: "Nouvelle création",
        DefineA: "Définir un",
        Delete: "Supprimer",
        DeselectAll: "Tout déselectionner",
        DontHaveAccessToAnyEntity: "Vous n'avez accès à aucune entité",
        Download: "Télécharger",
        Edit: "Modifier",
        Editing: "Édition de ",
        AddingEnglish: "Ajouter la valeur anglaise pour ",
        EditEntityExtent: "Édition de l'étendue d'une entité",
        EditZone: "Édition d'une zone",
        EditTreeVisibility: "Édition de la visibilité de l'arbre",
        ExcelExport: "Export Excel",
        False: "Faux",
        FullName: "Prénom et Nom",
        GetResults: "Obtenir les résultats",
        GINTExport: "Export gINT",
        GridView: "Affichage grille",
        Group: "Groupe",
        Help: "Aide",
        Hidden: "Caché",
        JsonExport: "Export JSON",
        Loading: "Chargement...",
        Location: "Emplacement",
        Mandatory: "Obligatoire",
        MapView: "Affichage carte",
        Move: "Déplacer",
        New: "Nouveau",
        NewFeaturesAreAdded: "Des nouvelles fonctionnalités sont ajoutées",
        newRef: "Requête d'une référence",
        Next: "Suivant",
        No: "Non",
        NoAlerts: "Pas d'alertes à afficher",
        NoResults: "Pas de résultats à afficher",
        OneTsigane: "One Tsigane",
        OpenNotifications: "Ouvrir les notifications",
        Overwrite: "Écraser",
        QuickSearchInto: "Recherche rapide dans :",
        QuickSearchFilter: "Options de filtre de recherche rapide",
        ParentFieldIsRequired: "Le champ Parent est obligatoire.",
        PreviewFile: "Prévisualiser le fichier",
        Private: "Privé",
        RedirectTo: "Rediriger vers :",
        Refresh: "Rafraîchir",
        ResultsFor: "Résultats pour %{value} ",
        RemoveAccessRight: "Retirer les droits d'accès",
        Role: "Statut",
        Search: "Recherche",
        SearchInTable: "Rechercher dans la grille",
        SeeAlerts: "Voir les alertes",
        SeeMore: "Voir plus d'informations",
        Select: "Sélectionner",
        SelectAll: "Tout sélectionner",
        SettingsPanel: "Panneau des paramètres",
        Skip: "Passer",
        SkipUpload: "Passer le téléversement",
        Close: "Fermer",
        Remove: "Effacer",
        Request: "Requête",
        Save: "Sauver",
        Translate: "Traduire",
        Tree: "Arbre",
        True: "Vrai",
        Workflow: "Flux de travail",
        Yes: "Oui",
        GenerateAToken: "Générer un token",
        CopytoClipboard: "Copier dans le presse papier",
        CopiedSuccessfully: "Copié avec succès",
		SavedSuccessfully: "Enregistré avec succès",
		featuresmessage: `Une nouvelle version (version ${SharedSettings.Version}) de l'application a été publiée. Elle inclut les nouvelles fonctionnalités suivantes:`,
        entitydelete: "Cela supprimera l’entité",
        operationundone: "Cette opération ne peut pas être annulée.",
        entitycode: "Pour confirmer, veuillez saisir le Code de l’Entité :",
		Error: "Rapport d'erreur",
		Upload: "Télécharger",
    },
    contact: {
        FileUploadedSuccessfully: "%{fileName} est bien ajouté",
        NoFileToDownload: "Aucun fichier à télécharger !",
        YouHaveAProblem: "Un problème",
        ContactForm: "Formulaire de contact",
        YouHaveAnIdea: "Une idée",
        QuestionAboutTheApp: "Une question sur l'application",
        WantToTrackAProblem: "Besoin de suivre un problème remonté",
        QuestionAboutUsingTheToolWithBranches: "Une question sur l'utilisation de l'outil au niveau des branches",
        BranchManagers: "Les branch managers"
    },
    diagram: {
        Draft: "Brouillon",
        Failed: "Échoué",
        Final: "Final",
        Finalize: "Finaliser",
        ForValidation: "À Valider",
        New: "Nouveau",
        NotValidated: "Pas Validé",
        NotValid: "Pas Valide",
        OK: "OK",
        Ok: "Ok",
        Processing: "En traitement",
        Reject: "Rejeter",
        Rejected: "Rejeté",
        Remove: "Retirer",
        Removed: "Retiré",
        Requested: "Demandé",
        Reset: "Réinitialiser",
        Rework: "Retravailler",
        Reviewed: "Examiné",
        Submit: "Soumettre",
        Submitted: "Soumis",
        UnderValidation: "En cours de validation",
        Validate: "Valider",
        Validated: "Validé",
        Valid: "Valide",
        Waiting: "En attente",
    },
    eddDataValidation: {
        ValidatingSheetsData: `Validation des données des "%{sheetName}"`,
        Stations: "Stations",
        Samplings: "Échantillons",
        InSituUndergroundMeasObs: "Obs de mesures souterraines in situ",
        LaboResult: "Résultats de Labo",
        SpeciesIdentification: "Identification des Espèces",
        EddValidationMayTakeSomeMinutes: "Le chargement peut prendre quelques minutes en fonction du nombre de données présentes dans l'EDD."
    },
    enum: {
        unitCategories: {
            NONE: "Aucune",
            CONDUCTANCE: "Conductance",
            CONDUCTIVITY: "Conductivité",
            FLAMMABILITY: "Inflammabilité",
            HIGHT_DEPTH: "Hauteur/Profondeur",
            LENGTH: "Longueur",
            MASS: "Masse",
            MASS_CONCENTRATIONS: "Concentration massique",
            MASS_FRACTIONS: "Fraction massique",
            MICROBIAL_CELLS_CONCENTRATION:
                "Concentration de cellules microbiennes",
            PARTS_PER_MASS: "Partie par : masse",
            PARTS_PER_VOLUME: "Partie par : volume",
            PERCENTAGE: "Pourcentage",
            PRESSURE: "Pression",
            SPEED: "Vélocité",
            TEMPERATURE: "Température",
            VOLTAGE: "Tension électrique",
            VOLUME: "Volume",
            VOLUME_FLOW_RATE: "Débit volumique",
            WATER_HARDNESS: "Dureté de l'eau",
            SPECIFIC_VOLUME: "Volume massique",
            RADIOACTIVITY_DESINTEGRATION_RATE: "Taux de désintégration radioactive",
            RADIOACTIVITY_PARTICLE_FLUX: "Flux de particule radioactive"
        },
        rights: {
            COMPANYMANAGER: "Manager de la société",
            HOLDINGMANAGER: "Manager du siège",
            ENTITYMANAGER: "Manager de l'entité",
            BRANCHMANAGER: "Manager de la branche",
            CONTRIBUTOR: "Contributeur",
            READER: "Lecteur",
            EXTERNAL: "Externe",
            GISMANAGER: "Manager SIG",
        },
    },
    field: {
        AdditionalExplanations: "Explications additionnelles",
        AnalysisGroup: "Groupe d'analyse",
        Author: "Auteur",
        Basemap: "Fond de carte",
        Branch: "Branche",
        Category: "Catégorie",
        Code: "Code",
        Color: "Couleur",
        Confidential: "Confidentiel",
        Country: "Pays",
        CreatedOn: "Date de création",
        CreatedByUser: "Créé par l'utilisateur",
        CreatedByUserDescription:
            "Identité de l'utilisateur ayant créé l'élément",
        Description: "Description",
        DocumentCategory: "Catégorie du document",
        EddFile: "Document EDD",
        EndDate: "Date de fin",
        Entity: "Entité",
        EntityCode: "Code de l'entité",
        EntityName: "Nom de l'entité",
        External: "Externe",
        ExternalProvider: "Fournisseur externe",
        Family: "Famille",
        File: "Document",
        Filename: "Nom du fichier",
        Firstname: "Prénom",
        Frequency: "Fréquence",
        GisManager: "Manager GIS",
        InitialStudy: "L'étude initiale",
        IsPrimaryEDDFile: "Document principal de l'EDD",
        Key: "Clé",
        Language: "Langue",
        Lastname: "Nom",
        LastUpdate: "Dernière mise à jour",
        LastUpdateDescription:
            "Date et heure de la dernière fois que l'élément ait été mis à jour",
        LookUpTableName: "Tables de référence",
        MapService: "Serveur de cartes",
        MaxValue: "Valeur Maximum",
        MinValue: "Valeur Minimum",
        Name: "Nom",
        NodeType: "Type de noeud",
        Note: "Note",
        Number: "Numéro",
        Observation: "Observation",
        Owner: "Propriétaire",
        Parent: "Parent",
        Phone: "Téléphone",
        PhotoContext: "Contexte de la photo",
        PointCoordinates: "Coordonnées du point",
        PolygonCoordinates: "Coordonnées du polygone",
        PublicationDate: "Date de publication",
        Public: "Publique", // fém. sing.
        QueryName: "Nom de la requête",
        Reference: "Référence",
        Region: "Région",
        RelatedZones: "Zones liées",
        RelatedStations: "Stations liées",
        RelatedStationGroups: "Groupe de stations lié",
        RelatedStudies: "Études liées",
        RequestedAccess: "Accès demandé",
        RequestAccessType: "Type d'accès demandé",
        RequestDate: "Date de la demande",
        RequestorIGG: "IGG du demandeur",
        RequestType: "Type de demande",
        ResultsSize: "Taille des résultats",
        SamplingID: "ID de l'échantillon",
        SubSamplingID: "ID du sous-échantillon",
        SampleType: "Type d'échantillon",
        Share: "Partage",
        SecurityGroupType: "Type du groupe de securité",
        StartDate: "Date de début",
        StartEndDate: "Date de début / de fin",
        State: "État",
        Station: "Station",
        StationAir: "Air",
        StationBiodiversity: "Biodiversité",
        StationCode: "Code station",
        StationGroups: "Groupe de stations",
        StationNoise: "Bruit",
        StationSedimentWater: "Eaux et sédiments",
        StationSediment: "Sédiments",
        StationWater: "Eaux",
        StationSoilGroundwater: "Sol et eaux souterraines",
        StationType: "Type de la station",
        Study: "L'étude",
        StudyType: "Type de l'étude",
        StudyYear: "Année de l'étude",
        Submitter: "Déposant",
        Title: "Titre",
        Units: "Unités",
        UpdatedByUser: "Mis à jour par",
        UpdatedByUserDescription:
            "Identité du dernier utilisateur ayant mis à jour l'élément",
        User: "Utilisateur",
        Value: "Valeur",
        Valid: "Valide",
        WorkOrder: "Commande",
        Zone: "Zone",
        EmailNotif: "Recevoir les notifications par e-mail",
        ExplanatoryText: "Jeton technique permettant l’accès aux API de One-Tsigane. Ce token est strictement personnel : votre identité sera référencée lors de son utilisation. Il est valable 365 jours : ",
    },
    grid: {
        title: {
            Administration: "Administration",
            Alerts: "Alertes",
            AllUsers: "Tous les utilisateurs",
            Analytes: "Analytes",
            Branches: "Branches",
            BranchManagers: "Managers de la branche",
            CompanyManagers: "Managers de l'entreprise",
            HoldingManagers: "Managers du siège",
            Configuration: "Configuration",
            UserPref: "Préférences utilisateur",
            Countries: "Pays",
            Documents: "Documents",
            EDDFiles: "Fichiers EDD",
            Entities: "Entités",
            EntityManagers: "Managers de l'entité",
            EntityUsers: "Utilisateurs de l'entité",
            ExternalUsers: "Utilisateurs externes",
            ExternalProviders: "Fournisseurs externes",
            Files: "Documents",
            Families: "Familles",
            GISManagers: "Managers GIS",
            InternalUsers: "Utilisateurs internes",
            LimitsOf: "Limites de",
            LookupHints: "Détails de la table de référence",
            NewFeaturesMessage: "Message pour les nouvelles fonctionnalités",
            PhotoVideo: "Photos et Videos",
            Queries: "Requêtes",
            Regions: "Régions",
            Requests: "Demandes à valider",
            MyRequests: "Mes demandes",
            ReportScheduler: "Programmation du reporting",
            Settings: "Paramètres",
            Samplings: "Échantillons",
            Stations: "Stations",
            VisibilityEditor: "Éditeur de visibilité",
            WorkorderExternalUsers: `Gérer les permissions d'accès à l'Étude "%{value}", Commande "%{value2}" `,
            Zones: "Zones",
			BulkUpload: "Téléchargement en masse",
        },
    },
    tree: {
        Add: "Ajouter un noeud enfant à %{value}",
        AddBookmark: "Ajouter aux signets",
        AddStudy: "Ajouter une étude à %{value}",
        AddStationGroup: "Ajouter un groupe de stations",
        Delete: "Supprimer %{value}",
        Edit: "Éditer  %{value}",
        EditTranslation: "Éditer les traductions de %{value}",
        Entities: "Entités",
        EntityTreeTemplate: "Modèle de l'arbre de l'entité",
        EntityTreeTemplateEditor: "Éditeur du modèle de l'arbre de l'entité",
        Limits: "Limits",
        Lookups: "Tables de Référence",
        Maps: "Cartes",
        References: "Références",
        RemoveFromBookmarks: "Supprimer des signets",
        Security: "Sécurité",
        StationGroupNodeEmpty:
            "Actuellement, il n'y a pas encore de groupe de stations à afficher. Cliquez ici pour en créer un nouveau.",
        StudyTemplate: "Modèle de l'étude",
        StudyTemplateEditor: "Éditeur du modèle de l'étude %{value}",
        Users: "Utilisateurs",
        Requests: "Demandes",
        StudyType: "Type édude",
        TextType: "Type texte",
    },
    dialog: {
        Alerts: "Alertes",
        AssignTo: "Assigner les %{value} à une %{value2}",
        AddNewLimits: "Ajouter de nouvelles limites.",
        CopyZonesFromStudies: "Copier les zones d'une étude à l'autre",
        OverwriteFileConfirmation:
            "Ce fichier existe déjà, êtes-vous sûr d'écraser les fichiers en cours de téléversement ?",
        PromptChangesHasOccured:
            "Êtes-vous sûr de vouloir naviguer loin de cette page?\n\nVous avez commencé à écrire ou à modifier une ligne dans la grille de données actuelle.\n\nAppuyez sur OK pour continuer ou annuler pour rester sur la page en cours et valider vos modifications.",
        DeleteConfirmationRow:
            "Êtes-vous sûr de supprimer cette ligne (élément)",
        DeleteConfirmationNode: "Êtes-vous sûr de supprimer ce noeud ?",
        Cancelled: "Annulé",
        SessionVerification:
            "Votre session est en cours d'être vérifée, merci de patienter quelques instants.",
        UploadSkipped: "Téléversement passé",
    },
    input: {
        validation: {
            required: "Ce champ est requis.",
            DateRangeValidation:
                "La date du début doit être avant la date de fin",
            DropFileAccepted: "Déposez les fichiers",
            DropFileRejected: `Désolé, seulement ${SharedSettings.MaxFilesToUpload} fichiers peuvent être déposés à la fois`,
            NumberChar: "Le nombre maximum de caractères pour ce champ est : ",
            WrongDocumentExtension: `Ceci est un dossier de documents: les fichiers acceptés ne peuvent pas être des images ou des vidéos, de fait leur téléversement est rejeté`,
            WrongPhotoVideoExtension: `Ceci est un dossier de photos/vidéos: les fichiers acceptés ne peuvent pas être d'une autre nature, de fait leur téléversement est rejeté `, // `This is a photo/video folder: the accepted files should be images or videos, otherwise their upload will be rejected.`,
            InvalidStartingDate: `La date de début ne peut pas être après la date de fin`,
            InvalidEndingDate:
                "La date de fin ne peut pas être avant la date de début",
            InvalidRange: "La valeur ne coïncide pas avec l'intervalle",
            InvalidTime:
                "L'heure saisie est incorrecte. Veuillez vérifier à nouveau. La plage horaire valide est comprise entre 00:00 et 23:59.",
            SelectStudy: "Veuillez d'abord sélectionner une étude",
        },
    },
    languages: {
        Dutch: "Néerlandais",
        English: "Anglais",
        French: "Français",
        German: "Allemand",
        Portuguese: "Portugais",
        Spanish: "Espagnol",
    },
    map: {
        addAPoint: "Ajouter un nouveau point",
        coordinates: "Coordonnées",
        errorSIG: "La connection vers le SIG n'a pas pu être établie.",
        errorLayerSIG:
            'La connection vers la couche SIG "%{layerName}" n\'a pas pu être établie.',
        entityOfInterest: "Entité concernée",
        extentOf: "Étendue de",
        latitude: "Latitude",
        longitude: "Longitude",
        mapServerLayers: "Couches du serveur SIG",
        point: "Point",
        polygon: "Polygone",
        redirectToStation: "Voir les details de la station",
        removeAPoint: "Retirer un point",
        mapServerLayersTypes: {
            LAYER_PICTURE: "Photos",
            LAYER_PLAN: "Secteurs",
            LAYER_TOPO: "Topographies",
            LAYER_PIPELINES: "Pipelines",
            LAYER_MISC: "Divers",
            LAYER_CADASTRE: "Cadastres",
            LAYER_ZONE: "Zones",
        },
        selectedStations: "Stations sélectionnées",
        stationGroupsLayers: "Couches des groupes de stations",
        stationsLayers: "Couches de stations",
        stationsOf: "Stations de",
        stationsOfGroup: "Stations du groupe",
        zonesOf: "Zones de",
        zonesExtents: "Étendues des zones",
    },
    notification: {
        noNotification: "Vous n'avez pas de notifications",
        errorNotifictation:
            "Les notifications ne sont pas disponibles pour le moment",
    },
    misc: {
        lambdaUser: "Utilisateur lambda",
        From: "depuis :",
    },
    panel: {
        EditEntity: "Éditer l'entité",
        NewEntity: "Nouvelle entité",
        NewSubEntity: "Nouvelle Sous-Entité"
    },
    placeholder: {
        Logout: "Se déconnecter",
        NoEntry: "Pas d'entrée",
        UserState: "Statut de l'utilisateur",
        EddFileState: "État de l'EDD",
    },
    select: {
        Contributor: "Contributeur",
        Reader: "Lecteur",
        External: "Externe",
    },
    view: {
        entityPage: {
            Code: "Code",
            Name: "Nom",
            Country: "Pays",
            Branch: "Branche",
            Parent: "Parent",
            Contact: "Contacts",
            Documents: "Documents",
            History: "Historique",
            Home: "Emplacement",
            Location: "Localisation",
            Operations: "Exploitation",
            Photo: "Photo",
        },
        helpPage: {
            Contact: "Contacts",
            Documents: "Documents",
            InternalUsers: "Utilisateurs internes",
            ExternalUsers: "Utilisateurs externes",
            contact: {
                FileUploadedSuccessfully: "%{fileName} a bien été ajouté",
                NoFileToDownload: "Aucun fichier à télécharger !",
                AnyTechnicalIssues: "Des problèmes techniques",
                PleaseClickHere: "Veuillez cliquer ici",
                AnyFurtherFunctionalities: "Souhaitez-vous d'autres fonctionnalités sur le système ",
                PleaseReferToThisLink: "Veuillez vous référer à ce lien",
                QuestionAboutTheApp: "Une question sur l'application",
                WantToTrackAProblem: "Besoin de suivre un problème remonté",
                ForSupportAtBranchLevel: "Pour une assistance au niveau des managers des branches, veuillez contacter",
            },
        },
        newUser: {
            CurrentRequests: "Demandes courantes",
            EntityAccessRequest: "Demande d'accès à une entité existante",
            EntityCreateRequest: "Demande de création d'une nouvelle entité",
            Welcome: "Bienvenue",
        },
        document: {
            AddMediaMetadata: "Ajouter des métadonnées à : ",
            CancelMove: "Annuler le déplacement",
            CurrentStudy: "L'étude courante : ",
            Destination: "Destination",
            DragAndDropFile: `Glisser-déposer un fichier par dessus cet écran pour téléverser. Ou utiliser le bouton sur la barre d'outils de la grille.`,
            Extension: "Extension",
            FilesRejected: "Les fichiers sont rejetés. ",
            HelpFileUpload: "Aide à propos du téléversement de fichiers",
            MaxFilesAllowed: `Une action de téléversement ne permet que ${SharedSettings.MaxFilesToUpload} fichiers maximum à la fois.`,
            MoveDocument: "Déplacer un document",
            OriginalStudy: "L'étude originale :",
            MoreInfo: "Plus d'informations",
            ListAllowedExtensions: "Liste des extensions de fichiers autorisés",
            SelectDestination: "Sélectionner la destination",
            UploadedBy: "Téléversé par :",
        },
        eddFiles: {
            getTemplate: "Télécharger le modèle de fichier EDD",
        },
        limit: {
            add: "Ajouter une nouvelle limite",
            addNewLimits: "Ajouter de nouvelles limites",
            limitSelection: "Définition et sélection de l'objet concerné",
            stepper: {
                CreateNewLimit: "Créer une nouvelle limite",
                EditLimit: "Modifier la limite",
            },
            objectSelection: {
                object: "Objet de la requête",
                parentObject: "Objet concerné",
            },
        },
        query: {
            ranOn: "Lancée le",
            ranOnThe: "lancée le",
            reporting: "Reporting",
            resultQuery: "Résulats de la requête",
            runQuery: "Lancer la requête",
            seeResultsOnMap: "Voir ces résultats sur une carte",
            stepper: {
                CreateNewQuery: "Créer une nouvelle requête",
                EditQuery: "Éditer la requête",
            },
            objectSelection: {
                objectSelection: "Sélection de l'objet de requête",
                object: "Objet de la requête",
                parentObject: "Objet parent",
                childObject: "Objet enfant",
                removeChild: "Retirer cet enfant",
            },
            conditions: {
                conditions: "Conditions",
            },
            outputSelection: {
                outputSelection: "Sélection des champs d'intérêt",
                Fieldname: "Nom du champ",
                Label: "Libellé",
                Description: "Description",
                Show: "Afficher",
            },
        },
        request: {
            ADDUSER: "Ajout d'un utilisateur",
            NEWENTITY: "Nouvelle entité",
            NEWREF: "Nouvelle référence",
            OptionalObservation: "Observation optionnelle",
        },
        station: {
            CreateNewStation: "Créer une nouvelle station",
            CreateStationGroup: "Crér un groupe de stations",
            EditStation: "Éditer la station",
            StationGroup: "Groupe de stations",
            StationStationGroup: "Les stations du groupe de stations :",
            StationGroupLoading:
                "Les stations du groupe de stations: chargement...",
        },
        study: {
            Study: "l'étude",
            StudyWorkOrder: "L'étude %{value} : commandes",
            NoStudyWorkOrder: "L'étude ... : commandes",
        },
        workOrder: {
            EDDFiles: "Fichiers EDD",
            ExternalUsersPermissions:
                "Permissions d'accès des utilisateurs externes",
        },
        zone: {
            CopyZones: "Copier les zones",
            CopyZonesFromStudy: "Copier les zones d'une étude",
            CreateNewZone: "Créer une nouvelle zone de",
            EditExtent: "Éditer l'étendue de",
            EditZone: "Éditer la zone",
            ExtendDefined: "Étendue définie",
            ExtendNotDefined: "Étendue non définie",
            LoadingEntity: "Chargement de l'entité ...",
            PreExistingZones: "Zones pré-existantes de",
        },
    },
    packages: {
        colorPicker: {
            language: "FRA",
            color: "couleur",
            rgb: "rvb",
            R: "R",
            G: "V",
            B: "B",
            hsv: "tsl",
            H: "T",
            S: "S",
            V: "V",
            hsl: "tsl",
            WrongFormat: "Format de couleur (Hex) invalide.",
            NotHexFormat: "Format de couleur (Hex) invalide.",
            transparent: "Transparent",
            none: "Invalide",
        },
        reactAwesomeQueryBuilder: {
            and: "et",
            or: "ou",
            valueLabel: "Valeur",
            valuePlaceholder: "Valeur",
            fieldLabel: "Champ",
            operatorLabel: "Opérateur",
            funcLabel: "Fonction",
            fieldPlaceholder: "Sélectionnez un champ",
            funcPlaceholder: "Sélectionnez une fonction",
            operatorPlaceholder: "Sélectionnez un opérateur",
            lockLabel: "Bloqué",
            lockedLabel: "Bloqué",
            deleteLabel: "",
            delGroupLabel: "",
            addGroupLabel: "Ajouter un groupe",
            addRuleLabel: "Ajouter une règle",
            addSubRuleLabel: "Ajouter une sous-règle",
            notLabel: "N'est pas",
            valueSourcesPopupTitle: "Sélectionnez un type de valeur",
            removeRuleConfirmOptions: {
                title: "Êtes-vous sûr de vouloir supprimer cette règle?",
                okText: "Oui",
                okType: "Attention",
                cancelText: "Annuler",
            },
            removeGroupConfirmOptions: {
                title: "Êtes-vous sûr de vouloir supprimer ce groupe ?",
                okText: "Oui",
                okType: "Attention",
                cancelText: "Annuler",
            },
            like: "comme",
            not_like: "pas comme",
            starts_with: "commence par",
            ends_with: "finit par",
            between: "entre",
            not_between: "pas entre",
            is_null: "est nul",
            is_not_null: "n'est pas nul",
            is_empty: "est vide",
            is_not_empty: "n'est pas vide",
            select_any_in: "parmis",
            select_not_any_in: "n'est pas dans",
            proximity: "à proximité",
            enterValue: "Entrez une valeur",
            enterNumber: "Entrez un nombre",
            enterNumberFrom: "Entrez un nombre de départ",
            enterDate: "Entrez une date",
            enterNumberTo: "Entrez un nombre de fin",
            enterInteger: "Entrz un entier",
            enterText: "Entrez un texte",
            selectValue: "Selectionnez une valeur",
            selectValues: "Selectionnez des valeurs",
        },
    },
    references: {
        Ref_AirType: "Air Type",
        Ref_AnalysisGroup: "Analysis Group",
        Ref_AquiferStatus: "Aquifer Status",
        Ref_BiodiversityType: "Biodiversity Type",
        Ref_BoreholeStatus: "Borehole Status",
        Ref_BoreholeType: "Borehole Type",
        Ref_CapType: "Cap Type",
        Ref_ConstructionType: "Construction Type",
        Ref_Context: "Context",
        Ref_DayNight: "Day and Night",
        Ref_DrillingFluid: "Drilling Fluid",
        Ref_DrillingMethod: "Drilling Method",
        Ref_EcologicalGroup: "Ecological Group",
        Ref_EcologicalIntegrity: "Ecological Integrity",
        Ref_EmissionSource: "Emission Source",
        Ref_EquipmentType: "Equipment Type",
        Ref_FaunaUse: "Fauna Use",
        Ref_GeometryType: "Geometry Type",
        Ref_HabitatType: "Habitat Type",
        Ref_HumanUse: "Human Use",
        Ref_Installation: "Installation",
        Ref_IUCNStatus: "IUCN Status",
        Ref_LithologyType: "Lithology Type",
        Ref_Matrix: "Matrix",
        Ref_ObsMeasure: "Obs Measure",
        Ref_ObservationMode: "Observation Mode",
        Ref_ObservationType: "Observation Type",
        Ref_PhotoContext: "Photo Context",
        Ref_PiezometerGroup: "Piezometer Group",
        Ref_PresenceProof: "Presence Proof",
        Ref_QAQCType: "QAQC Type",
        Ref_ReadingType: "Reading Type",
        Ref_RecordPeriod: "Record Period",
        Ref_RelativePosition: "Relative Position",
        Ref_ReleaseLocation: "Release Location",
        Ref_SampleCollectionType: "Sample Collection Type",
        Ref_SampleType: "Sample Type",
        Ref_SensorType: "Sensor Type",
        Ref_StationBiodiversityType: "Station Biodiversity",
        Ref_StationSedimentWaterType: "Station Sediment Water",
        Ref_StratigraphicClassification: "Stratigraphic Classification",
        Ref_TrophicGroup: "Trophic Group",
        Ref_Unit: "Units",
        Ref_Vulnerability: "Vulnerability",
        Ref_WaterIntermittence: "Water Intermittence",
        Ref_WaterLocation: "Water Location",
        Ref_WaterSource: "Water Source",
        Ref_WaterType: "Water Type",
        Ref_WeightBasis: "Weight Basis",
    },
};
export default FRA;
