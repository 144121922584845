import React, { useCallback, useState } from "react";
import {
    GridActionsCellItem,
    GridAddIcon,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { requiredCell } from "../../js/utils/inputValidations";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useDispatch, useSelector } from "react-redux";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import { TranslationDialog } from "./cst_DataGrid";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { updateHighlightedRows } from "../../js/redux/actions/userPref";
import { useLocation } from "react-router-dom";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { EnglishValueDialog, NewRefDialog } from "./ReferencesDataGrid";
import { getDownload, excelSpreadsheetMIME, jsonMIME } from "../../js/utils/backend";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

const FamilyGrid = ({ baseURL, label }) => {
    const apiRef = useGridApiRef();
    const cellFocusField = "Value";
    const { CompanyManager, HoldingManager } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const currentLocation = location.pathname;
    const editingRights = CompanyManager || HoldingManager;

    const locale = useSelector(state => state.i18n.locale);
    const token = useSelector(state => state.auth.Token);
    const [propsEnglishValue, setOpenEnglishValue] = useState(false);
    const beforeCommitRow = useCallback(
        row => {
            if (row.isNew && locale !== "ENG") {
                return new Promise((resolve, reject) => {
                    setOpenEnglishValue({
                        open: true,
                        setOpen: setOpenEnglishValue,
                        resolve,
                        reject,
                        row,
                        label,
                    });
                }).then(englishValue => ({
                    ...row,
                    EnglishValue: englishValue,
                }));
            }
            return row;
        },
        [setOpenEnglishValue, locale, label]
    );

    const gridSpecificColumns = [
        {
            field: "Value",
            headerName: t("field.Name"),
            flex: 1,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "Name", "Value");
                return { ...params.props, error: hasError };
            },
        },
    ];

    const [open, setOpen] = useState(false);
    const [rowParams, setRowParams] = useState();
    const [addRequest, setAddRequest] = useState(false);
    const specificActionsColumns = (params, editing, isLocalLoading) => [
        <>
            {(CompanyManager || HoldingManager) && (
                <GridActionsCellItem
                    label={t("common.Translate")}
                    icon={
                        <BasicTooltip title={t("common.Translate")}>
                            <LanguageIcon />
                        </BasicTooltip>
                    }
                    onClick={() => {
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: currentLocation,
                                VisitedRowID: params.row.ID,
                            })
                        );
                        setOpen(true);
                        setRowParams(params.row);
                    }}
                    disabled={editing || isLocalLoading}
                />
            )}
        </>,
    ];

    return (
        <>
            <TranslationDialog
                label={label}
                open={open}
                setOpen={setOpen}
                rowParams={rowParams}
                baseURL={baseURL}
            />
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                cellFocusField={cellFocusField}
                label={label}
                toolbar={{
                    newAction: (
                        <>
                            {!CompanyManager && !HoldingManager && (
                                <ButtonToolbar
                                    titleTooltip={t("common.addNewRef")}
                                    title={t("common.addNewRef")}
                                    startIcon={<GridAddIcon />}
                                    onClick={() => {
                                        setAddRequest({ node: {} });
                                    }}
                                />
                            )}
                            <ButtonToolbar
                                titleTooltip={t(
                                    "common.ExcelExport"
                                )}
                                title={t(
                                    "common.ExcelExport"
                                )}
                                startIcon={
                                    <DownloadOutlinedIcon
                                        sx={{ marginRight: "-4px" }}
                                    />
                                }
                                onClick={() => {
                                    /** The request should absolutely be the same as paginated list for grid, while ommiting specific grid-related things like pageSize and pageNumber */
                                    let url = `${baseURL}/ExportToExcel`;
                                    getDownload(
                                        url,
                                        token,
                                        excelSpreadsheetMIME,
                                        `${baseURL}`
                                    );
                                }}
                            />
                            <ButtonToolbar
                                titleTooltip={t(
                                    "common.JsonExport"
                                )}
                                title={t(
                                    "common.JsonExport"
                                )}
                                startIcon={
                                    <DownloadOutlinedIcon
                                        sx={{ marginRight: "-4px" }}
                                    />
                                }
                                onClick={() => {
                                    let url = `${baseURL}/ExportToJson`;
                                    getDownload(
                                        url,
                                        token,
                                        jsonMIME,
                                        `${baseURL}`
                                    );
                                }}
                            />
                        </>
                    ),
                }}
                editingRights={editingRights}
                gridSpecificColumns={gridSpecificColumns}
                specificActionsColumns={specificActionsColumns}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "Value", sort: "asc" }],
                    },
                }}
                beforeCommitRow={beforeCommitRow}
            />
            <NewRefDialog
                reference={"Families"}
                editNewRefOpen={addRequest}
                seteditNewRefOpen={setAddRequest}
            />
            <EnglishValueDialog {...propsEnglishValue} />
        </>
    );
};
export default FamilyGrid;
