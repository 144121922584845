import React from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import * as r from "../../js/constants/routes";
import { SelectURLGrid } from "../Widgets/Custom Inputs/Select";
import { requiredCell } from "../../js/utils/inputValidations";
import DataGridComponent from "../Widgets/DataGridComponent";
import { t } from "react-i18nify";
import { useEditionRightsPerUserStatus } from "../../js/utils/customHooks";
import { filterValidUser } from "./cst_DataGrid";
import { ListItem, ListItemText } from "@mui/material";

const EntityManagerGrid = ({
    label = t("grid.title.EntityManagers"),
    baseURL,
}) => {
    const apiRef = useGridApiRef();
    const cellFocusField = "OTUser_select";
    const editingRights = useEditionRightsPerUserStatus([
        "BranchManager",
        "EntityManager",
    ]);

    const gridSpecificColumns = [
        {
            field: "OTUser_IGG",
            headerName: "IGG",
            sortable: false,
            flex: 0.5,
            valueGetter: params => params !== undefined ? params.row.OTUser_select?.IGG : "",
        },
        {
            field: "OTUser_select",
            headerName: t("field.User"),
            sortable: false,
            flex: 1,
            editable: true,
            valueFormatter: params => params.value?.Name,
            renderEditCell: params => (
                <SelectURLGrid
                    fieldName={"OTUserID"}
                    selectRoute={"SelectDetail"}
                    URL={r.oTUser}
                    paramURL={filterValidUser}
                    getOptionLabel={option => option.Name ?? option.IGG}
                    filterOptions={options => options}
                    renderOption={(props, option) => (
                        <ListItem {...props} key={option.ObjectID}>
                            <ListItemText
                                sx={{
                                    margin: 0,
                                    padding: 0,
                                }}
                                inset
                                primary={option.Name}
                                secondary={option.IGG}
                            />
                        </ListItem>
                    )}
                    {...params}
                />
            ),
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "User",
                    "OTUser_select"
                );
                return { ...params.props, error: hasError };
            },
            valueSetter: params => ({
                ...params.row,
                EMail: params.value.EMail,
            }),
        },
        {
            field: "OTUserID",
            editable: false,
        },
        {
            field: "EMail",
            headerName: "Email",
            sortable: false,
            flex: 0.5,
            valueGetter: params => params.row.OTUser_select?.EMail,
        },
        {
            field: "OTEntity_select",
            headerName: t("field.Entity"),
            sortable: false,
            flex: 1,
            width: 200,
            editable: true,
            valueFormatter: params => params.value?.Name,
            renderEditCell: params => (
                <SelectURLGrid
                    fieldName={"OTEntityID"}
                    URL={r.oTEntity}
                    {...params}
                />
            ),
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "Entity",
                    "OTEntity_select"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "OTEntityID",
            valueGetter: params => params.row?.OTEntity_select?.ObjectID,
        },
    ];

    return (
        <DataGridComponent
            apiRef={apiRef}
            baseURL={baseURL}
            columnVisibilityModel={{
                "OTUserID": false,
                "OTEntityID": false
            }}
            cellFocusField={cellFocusField}
            label={label}
            gridSpecificColumns={gridSpecificColumns}
            isEditDisabled={true}
            initialState={{
                sorting: {
                    sortModel: [{ field: "OTUserID", sort: "asc" }],
                },
            }}
            editingRights={editingRights}
        />
    );
};

export default EntityManagerGrid;
